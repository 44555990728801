<script>
import autocompleteApi from "../../api/autocompleteApi";
import railPlacesUtils from "../../utils/autocomplete/railPlacesUtils";
import AgencyPortalAutocompleteVue from "../forms-element/autocomplete/AgencyPortalAutocomplete.vue";
import DateUtils from "../../utils/DateUtils";
import { mapActions, mapGetters } from "vuex";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    AgencyPortalAutocompleteVue
  },
  mixins: [validationMixin],
  props: {
    isInTrainListPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTicketBtnDisabled: false,
      zeroResult: false,
      isRoundTrip: false,
      isTravelerOverMaxNum: false,
      isTravelerEmpty: false,
      isDateTimeRangeWork: true,
      dateCtrl: {
        departureDateMin: new Date(),
        departureDateMax: null,
        returnDateMin: new Date(),
        returnDateMax: null,
        isReturnTimeOk: true
      },
      validatorReturn: {
        name: "required",
        length: 0
      },
      alertSettings: {
        dismissCountDown: 0,
        countDownSecs: 4,
        title: "",
        alertMsg: "",
        alertVariant: "warning"
      },
      passengerSelected: {
        adult: null,
        youth: null,
        senior: null
      },
      /*
                sample data
                    [{value: 'IT:fabriano', text: 'Fabriano'}]
                */
      suggestions: [],
      searchInputValues: {
        fromPlace: "",
        fromPlaceName: "",
        fromPlaceType: "",
        toPlace: "",
        toPlaceName: "",
        toPlaceType: "",
        departureDate: "",
        departureTime: "08:00:00",
        returnDate: "",
        returnTime: "08:00:00",
        adult: 1,
        youth: 0,
        youthAges: [],
        senior: 0
      },
      errorMsgs: {
        fromPlace: {
          text: "Please select your departure station"
        },
        toPlace: {
          text: "Please select your arrival station"
        },
        departureDate: {
          text: "Departure date is required"
        },
        departureTime: {
          text: "Departure time is required"
        },
        returnDate: {
          text: "Return date is required"
        },
        returnTime: {
          text: "Return time is required"
        },
        timeNotOk: {
          text: "Return date & time should be after departure date & time"
        },
        alone: {
          text: "Youth should not travel alone"
        },
        zero: {
          text: "There should be at least one passenger"
        },
        country: {
          text: "Please select a country to visit"
        },
        validFrom: {
          text: "Please set a date that valid from"
        }
      },
      tipMsgs: {
        alone: {
          text: "Youth should not travel alone"
        },
        zeroResult: {
          text: "There is no ticket for your trip"
        },
        maximum: {
          text: "Max. 9 travelers"
        }
      }
    };
  },
  validations() {
    if (this.isRoundTrip) {
      return {
        searchInputValues: {
          fromPlace: {
            required
          },
          fromPlaceName: {
            required
          },
          toPlace: {
            required
          },
          toPlaceName: {
            required
          },
          departureDate: {
            required
          },
          departureTime: {
            required
          },
          returnDate: {
            required
          },
          returnTime: {
            required
          },
          adult: {},
          youth: {},
          senior: {},
          youthAges: {}
        }
      };
    } else {
      return {
        searchInputValues: {
          fromPlace: {
            required
          },
          fromPlaceName: {
            required
          },
          toPlace: {
            required
          },
          toPlaceName: {
            required
          },
          departureDate: {
            required
          },
          departureTime: {
            required
          },
          adult: {},
          youth: {},
          senior: {},
          youthAges: {}
        }
      };
    }
  },
  computed: {
    ...mapGetters("rail", [
      "trainTicketsRS",
      "railPassesRS",
      "railPassResults",
      "outboundTrainTicketResults"
    ])
  },
  watch: {
    outboundTrainTicketResults: function() {
      this.searchTicketBtnDisabled = false;
      this.$nextTick(function() {
        if (
          !this.outboundTrainTicketResults ||
          this.outboundTrainTicketResults.length === 0
        ) {
          // this.zeroResult = true
          // this.alertSettings.title = "Message";
          // this.alertSettings.alertMsg = "There is no ticket for your trip";
          // this.alertSettings.alertVariant = "warning";
          // this.alertSettings.dismissCountDown =
          //   this.alertSettings.countDownSecs + 10;
        } else {
          if (!this.isInTrainListPage) {
            this.$router.push({ name: "trains" });
          }
        }
      });
    },
    trainTicketsRS: function() {
      if (!this.trainTicketsRS.success) {
        this.searchTicketBtnDisabled = false;

        this.alertSettings.title = this.trainTicketsRS.errorTitle;
        this.alertSettings.alertMsg = this.trainTicketsRS.errorMsg;
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      } else {
        this.searchTicketBtnDisabled = false;
      }
    }
  },
  methods: {
    ...mapActions("rail", [
      "searchTrainTickets",
      "updateSearchCriteria",
      "updateTrainTickets"
    ]),
    countDownChanged(dismissCountDown) {
      this.alertSettings.dismissCountDown = dismissCountDown;
    },
    validateState(field) {
      const { $dirty, $error } = this.$v.searchInputValues[field];
      return $dirty ? !$error : null;
    },
    validatePassengers(errorCause) {
      if (
        this.searchInputValues.adult +
          this.searchInputValues.senior +
          this.searchInputValues.youth >
        9
      ) {
        this.isTravelerOverMaxNum = true;
      } else {
        this.isTravelerOverMaxNum = false;
      }

      if (this.$v.searchInputValues.$dirty) {
        let isTravelAlone =
          this.searchInputValues.adult == 0 &&
          this.searchInputValues.senior == 0 &&
          this.searchInputValues.youth > 0;
        let isAllZero =
          this.searchInputValues.adult == 0 &&
          this.searchInputValues.senior == 0 &&
          this.searchInputValues.youth == 0;

        if (errorCause === "alone") return isTravelAlone;
        if (errorCause === "zero") return !isAllZero;

        return null;
      }
      return null;
    },
    checkDateTime() {
      if (this.isRoundTrip) {
        this.isDateTimeRangeWork = DateUtils.isBefore(
          this.searchInputValues.departureDate +
            " " +
            this.searchInputValues.departureTime,
          this.searchInputValues.returnDate +
            " " +
            this.searchInputValues.returnTime
        );
      } else {
        this.isDateTimeRangeWork = true;
      }
    },
    validateDate(fieldName) {
      let fieldValue = this.searchInputValues[fieldName];
      let isValid = dayjs(fieldValue, "YYYY-MM-DD", true).isValid();

      if (isValid) {
        let today = new Date(DateUtils.format(new Date()));
        if (
          this.searchInputValues.departureDate != null &&
          this.searchInputValues.departureDate != ""
        ) {
          let date = new Date(this.searchInputValues.departureDate);
          if (date < today) {
            this.searchInputValues.departureDate = "";
          }
        }

        if (
          this.searchInputValues.returnDate != null &&
          this.searchInputValues.returnDate != ""
        ) {
          let date = new Date(this.searchInputValues.returnDate);
          if (date < today) {
            this.searchInputValues.returnDate = "";
          }
        }

        if (
          this.searchInputValues.departureDate != null &&
          this.searchInputValues.departureDate != "" &&
          this.searchInputValues.returnDate != null &&
          this.searchInputValues.returnDate != ""
        ) {
          this.isDateTimeRangeWork = DateUtils.isBefore(
            this.searchInputValues.departureDate +
              " " +
              this.searchInputValues.departureTime,
            this.searchInputValues.returnDate +
              " " +
              this.searchInputValues.returnTime
          );
        }

        return;
      }

      if (fieldName == "departureDate") {
        this.searchInputValues.departureDate = "";
      } else if (fieldName == "returnDate") {
        this.searchInputValues.returnDate = "";
      }
    },
    inputDateTime(value) {
      console.info(value);
    },
    // str is the input value of from/to input field
    getSuggestedStations(str) {
      autocompleteApi.railPlaces(str).then(res => {
        let items = res.data;
        let destinations = railPlacesUtils.dataSuggestions(items);
        this.suggestions = destinations;
      });
    },
    selectTrainStationFrom(selectedPlace) {
      //put properties of selectedPlace into searchInputValues as param
      this.searchInputValues.fromPlace = selectedPlace.code;
      this.searchInputValues.fromPlaceType = selectedPlace.type;
    },
    inputTranStation(direct) {
      if (direct == "from") {
        this.searchInputValues.fromPlace = null;
      } else if (direct == "to") {
        this.searchInputValues.toPlace = null;
      }
    },
    blurTranStation(direct) {
      if (direct == "from") {
        this.$v.searchInputValues.fromPlace.$touch();
      } else if (direct == "to") {
        this.$v.searchInputValues.toPlace.$touch();
      }
    },
    selectTrainStationTo(selectedPlace) {
      //put selectedPlace into searchInputValues as param
      this.searchInputValues.toPlace = selectedPlace.code;
      this.searchInputValues.toPlaceType = selectedPlace.type;
    },
    searchTickets() {
      this.$v.$touch();
      this.zeroResult = false;
      let isAllOkToGo = true;
      this.checkDateTime();
      this.isTravelerEmpty =
        this.searchInputValues.adult === 0 &&
        this.searchInputValues.senior === 0 &&
        this.searchInputValues.youth === 0;
      let youthOnly =
        this.searchInputValues.adult === 0 &&
        this.searchInputValues.senior === 0 &&
        this.searchInputValues.youth !== 0 &&
        this.searchInputValues.youthAges &&
        this.searchInputValues.youthAges[0].age < 15;

      if (this.isTravelerEmpty) {
        this.alertSettings.title = "Message";
        this.alertSettings.alertMsg = "There should be at least one passenger";
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
        return;
      }
      if (youthOnly) {
        this.alertSettings.title = "Message";
        this.alertSettings.alertMsg =
          "Youth travelers under 15 years old cannot travel alone for this trip";
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
        return;
      }

      isAllOkToGo =
        !this.$v.searchInputValues.$invalid &&
        !this.isTravelerOverMaxNum &&
        !this.isTravelerEmpty &&
        this.isDateTimeRangeWork;
      if (isAllOkToGo) {
        this.updateSearchCriteria({
          searchInputValues: this.searchInputValues,
          isRoundTrip: this.isRoundTrip
        });
        this.searchTicketBtnDisabled = !this.searchTicketBtnDisabled;

        if (this.searchInputValues == null) {
          sessionStorage.removeItem("searchInputValues");
        } else {
          sessionStorage.setItem(
            "searchInputValues",
            JSON.stringify(this.searchInputValues)
          );
        }
        sessionStorage.setItem("isRoundTrip", JSON.stringify(this.isRoundTrip));

        this.searchTrainTickets({
          searchInputValues: this.searchInputValues,
          isRoundTrip: this.isRoundTrip
        });
        this.$emit("reSearch");
      }
    },
    generateTicketAgeFields(childNumber) {
      let itemsAddedOrRemoved =
        childNumber - this.searchInputValues.youthAges.length;
      for (let i = 0; i < Math.abs(itemsAddedOrRemoved); i++) {
        if (itemsAddedOrRemoved > 0) {
          this.searchInputValues.youthAges.push({ age: 0 });
        } else if (itemsAddedOrRemoved < 0) {
          this.searchInputValues.youthAges.pop();
        }
      }
    },
    out(value) {
      console.info(value);
    }
  },
  created() {
    if (sessionStorage.getItem("searchInputValues")) {
      this.searchInputValues = JSON.parse(
        sessionStorage.getItem("searchInputValues")
      );
    }
    if (sessionStorage.getItem("isRoundTrip")) {
      this.isRoundTrip = JSON.parse(sessionStorage.getItem("isRoundTrip"));
    }
  }
};
</script>

<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-radio-group v-model="isRoundTrip" disabled-field="notEnabled">
            <b-form-radio
              v-model="isRoundTrip"
              name="trip-type-radios"
              :value="false"
              >One Way</b-form-radio
            >
            <b-form-radio
              v-model="isRoundTrip"
              name="trip-type-radios"
              :value="true"
              >Round Trip</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <AgencyPortalAutocompleteVue
            v-model="$v.searchInputValues.fromPlaceName.$model"
            @select-value="selectTrainStationFrom"
            @input="() => inputTranStation('from')"
            @blur="() => blurTranStation('from')"
            @fetch="getSuggestedStations"
            :suggestions="suggestions"
            id="from-place"
            placeholder="From"
            datalistId="station-from"
            :state="validateState('fromPlace')"
            :aria-describedby="'from-place-feedback'"
            :state-msg="errorMsgs.fromPlace.text"
          ></AgencyPortalAutocompleteVue>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <AgencyPortalAutocompleteVue
            v-model="$v.searchInputValues.toPlaceName.$model"
            @select-value="selectTrainStationTo"
            @input="() => inputTranStation('to')"
            @blur="() => blurTranStation('to')"
            @fetch="getSuggestedStations"
            :suggestions="suggestions"
            id="to-place"
            placeholder="To"
            datalistId="station-to"
            :state="validateState('toPlace')"
            :aria-describedby="'from-to-feedback'"
            :state-msg="errorMsgs.toPlace.text"
          ></AgencyPortalAutocompleteVue>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <b-input-group
          :class="{
            'form-group--error': $v.searchInputValues.departureDate.$error
          }"
        >
          <b-input-group-prepend>
            <b-form-datepicker
              placeholder="Departure Date"
              :min="dateCtrl.departureDateMin"
              v-model="$v.searchInputValues.departureDate.$model"
              show-decade-nav
              button-only
              @input="validateDate('departureDate')"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              :state="validateState('departureDate')"
              aria-describedby="departure-date-feedback"
            ></b-form-datepicker>
          </b-input-group-prepend>
          <b-form-input
            id="example-input"
            v-model="$v.searchInputValues.departureDate.$model"
            type="text"
            placeholder="Departure Date-YYYY-MM-DD"
            autocomplete="off"
            @blur="validateDate('departureDate')"
          ></b-form-input>
        </b-input-group>
        <span
          v-if="$v.searchInputValues.departureDate.$error"
          class="error-message"
        >
          {{ errorMsgs.departureDate.text }}
        </span>
      </b-col>
      <b-col cols="2">
        <b-form-group
          :class="{
            'form-group--error': $v.searchInputValues.departureTime.$error
          }"
        >
          <b-form-timepicker
            placeholder="Departure Time"
            v-model="$v.searchInputValues.departureTime.$model"
            minutes-step="30"
          ></b-form-timepicker>
          <span v-show="$v.searchInputValues.departureTime.$error">
            {{ errorMsgs.departureTime.text }}
          </span>
        </b-form-group>
      </b-col>

      <!-- for round trip only -->
      <!-- start -->
      <b-col cols="4" v-if="isRoundTrip">
        <b-input-group
          :class="{
            'form-group--error': $v.searchInputValues.returnDate.$error
          }"
        >
          <b-input-group-prepend>
            <b-form-datepicker
              placeholder="Return Date"
              show-decade-nav
              button-only
              :min="dateCtrl.returnDateMin"
              @input="validateDate('returnDate')"
              v-model="$v.searchInputValues.returnDate.$model"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
            ></b-form-datepicker>
          </b-input-group-prepend>
          <b-form-input
            id="example-input"
            v-model="$v.searchInputValues.returnDate.$model"
            type="text"
            @blur="validateDate('returnDate')"
            placeholder="Return Date-YYYY-MM-DD"
            autocomplete="off"
          ></b-form-input>
        </b-input-group>
        <span
          v-if="$v.searchInputValues.returnDate.$error"
          class="error-message"
        >
          {{ errorMsgs.returnDate.text }}
        </span>
      </b-col>
      <b-col cols="2" v-if="isRoundTrip">
        <b-form-group
          :class="{
            'form-group--error': $v.searchInputValues.returnTime.$error
          }"
        >
          <b-form-timepicker
            placeholder="Return Time"
            v-model="$v.searchInputValues.returnTime.$model"
            minutes-step="30"
            @input="validateDate('returnDate')"
          ></b-form-timepicker>
          <span v-show="$v.searchInputValues.returnTime.$error">
            {{ errorMsgs.returnTime.text }}</span
          >
        </b-form-group>
      </b-col>
      <!-- end -->
    </b-row>
    <b-form-invalid-feedback :state="isDateTimeRangeWork">
      {{ errorMsgs.timeNotOk.text }}</b-form-invalid-feedback
    >
    <b-row>
      <b-col cols="2">
        <b-form-group label="Adult(26-59)">
          <!-- <b-form-select v-model="$v.searchInputValues.adult.$model" 
                    :options="passengerTypes.adult" placeholder="Adult(26-59)"
                    aria-describedby="adult-feedback"
                    ></b-form-select>
                    <b-form-invalid-feedback id="adult-feedback">
                            </b-form-invalid-feedback> -->
          <b-form-spinbutton
            v-model="searchInputValues.adult"
            :max="9"
            :min="0"
            placeholder="Adult(26-59)"
            aria-describedby="adult-feedback"
          ></b-form-spinbutton>
          <b-form-invalid-feedback id="adult-feedback">
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            id="max-num-feedback"
            :state="!isTravelerOverMaxNum"
            >{{ tipMsgs.maximum.text }}</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Senior(60+)">
          <!-- <b-form-select v-model="searchInputValues.senior" 
                    :options="passengerTypes.senior" placeholder="Senior(60+)"
                    aria-describedby="senior-feedback"
                    ></b-form-select>
                    <b-form-invalid-feedback id="senior-feedback">
                            </b-form-invalid-feedback> -->
          <b-form-spinbutton
            v-model="searchInputValues.senior"
            :max="9"
            :min="0"
            placeholder="Senior(60+)"
            aria-describedby="senior-feedback"
          ></b-form-spinbutton>
          <b-form-invalid-feedback id="senior-feedback">
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Youth/Child(0-25)">
          <!-- <b-form-select v-model="$v.searchInputValues.youth.$model" 
                    :options="passengerTypes.youth" placeholder="Youth/Child(0-25)"
                    @change="generateTicketAgeFields"
                    aria-describedby="youth-feedback"
                    ></b-form-select>
                    <b-form-valid-feedback id="youth-feedback">
                            </b-form-valid-feedback> -->
          <b-form-spinbutton
            v-model="searchInputValues.youth"
            :max="9"
            :min="0"
            placeholder="Youth/Child(0-25)"
            @change="generateTicketAgeFields"
            aria-describedby="youth-feedback"
          ></b-form-spinbutton>
          <b-form-valid-feedback id="youth-feedback"> </b-form-valid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="searchInputValues.youthAges.length > 0">
        <b-form-group label="Age">
          <div class="d-flex">
            <b-form-input
              class="mx-1 ageField-max-width"
              type="number"
              v-for="(item, index) in searchInputValues.youthAges"
              placeholder="Age"
              :max="25"
              :min="0"
              v-model="item.age"
              :key="'child-age-' + index"
            ></b-form-input>
          </div>
        </b-form-group>
        <p class="m-2" v-show="searchInputValues.youthAges.length > 0">
          Enter the ages of youths on the day of travel
          <i
            class="dripicons-question"
            v-b-tooltip.hover
            id="youth-age-tooltip"
          ></i>
          <b-tooltip target="youth-age-tooltip" triggers="hover">
            Ages are about ticket discounts
          </b-tooltip>
        </p>
      </b-col>
      <!-- <b-col>
                <b-form-spinbutton 
                class="mx-1"
                vertical
                v-for="item, index in searchInputValues.youthAges"
                placeholder="Age"
                :max="25"
                :min="0"
                v-model="item.age"
                :key="'child-age-'+index"
                ></b-form-spinbutton>
                <p class="m-2" v-show="(searchInputValues.youthAges.length > 0)">
                    Enter the ages of youths on the day of travel 
                    <i class="dripicons-question" v-b-tooltip.hover id="youth-age-tooltip"></i>
                    <b-tooltip target="youth-age-tooltip" triggers="hover">
                        Ages are about ticket discounts  
                    </b-tooltip>
                </p>
            </b-col> -->
    </b-row>
    <b-row class="text-center">
      <b-col>
        <!-- <b-form-valid-feedback id="alone-feedback"
                :state="validatePassengers('alone')"
                >{{tipMsgs.alone.text}}</b-form-valid-feedback> -->

        <!--                <b-form-invalid-feedback id="zero-feedback"-->
        <!--                :state="validatePassengers('zero')"-->
        <!--                >{{errorMsgs.zero.text}}</b-form-invalid-feedback>-->

        <b-form-valid-feedback id="zero-result-feedback" :state="zeroResult">{{
          tipMsgs.zeroResult.text
        }}</b-form-valid-feedback>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col cols="12">
        <b-button
          variant="success"
          @click="searchTickets"
          :disabled="searchTicketBtnDisabled"
        >
          <template>
            <b-spinner small v-if="searchTicketBtnDisabled"></b-spinner>
            <span class="">{{
              searchTicketBtnDisabled ? "Loading..." : "Search For Trains"
            }}</span>
          </template>
        </b-button>
      </b-col>
    </b-row>
    <b-alert
      :show="alertSettings.dismissCountDown"
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000;"
      :variant="alertSettings.alertVariant"
      dismissible
      @dismiss-count-down="countDownChanged"
    >
      <!--                <div v-html="alertSettings.title"></div>-->
      <div v-html="alertSettings.alertMsg"></div>
    </b-alert>
  </b-container>
</template>

<style scoped>
.ageField-max-width {
  min-width: 70px !important;
  max-width: 70px !important;
}
</style>
